require('./bootstrap');
require('alpinejs');
import imageCompression from 'browser-image-compression';

export default {
    async getCompressImageFile(file) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
        }
        return await imageCompression(file, options)
    },
}
